import axios from "axios";
import router from "@/router";
import { ElMessage,ElMessageBox,ElNotification } from 'element-plus';

const myAxios = axios.create({
    // baseURL: window?.microApp?.getData()?.baseURL || '',
    timeout: 9000
});

myAxios.interceptors.request.use(config => {
    let token = localStorage.getItem("token");
    if(token){
        config.headers.Authorization = 'Bearer ' + token
    }
    return config
}, err => {
    Promise.reject(err)
})

myAxios.interceptors.response.use(res => {
    if(res.status>=200 && res.status<300 && res){
        return Promise.resolve(res)
    }
}, error => {
    if(error?.response?.status == 401){
        router.push('/tologin');
        return;
    }

    if(error?.response?.status == 422){
        let errorinfo = error.response.data.errors;
        if(!errorinfo){return}
        let keys = Object.keys(errorinfo);
        let messages = keys.map(key => {
            return errorinfo[key].join('\n');
        });

        ElMessageBox.alert(messages.join('<br/>'),"提示",{confirmButtonText:"确定",dangerouslyUseHTMLString:true});
        
        return Promise.reject(error);
    }
    
    if(error?.response?.status == 429){return}
    if(error?.response?.status == 408){return}
    if(!error?.config?.dontalert){
        if(error.response && !error.config.headers.cancelerror && error.response?.data?.error) {
            ElMessage({
                message: error.response.data.error,
                duration: 3000,
                type:'error',
            });
        }else{
            let data = error?.response?.data;
            let str = "";
            let dangerouslyUseHTMLString = false;
            if (typeof data ==='string') {
                str = data;
            }else{
                try {
                    str = JSON.stringify(data, null, 4);
                    str = `<pre>${str}</pre>`;
                    dangerouslyUseHTMLString = true;
                } catch (e) { str = data; }
            }
            ElNotification({
                title: 'Error',
                dangerouslyUseHTMLString: dangerouslyUseHTMLString,
                message: str,
                type: 'error',
                customClass: 'custom-width-class',
            })
        }
    }

    return Promise.reject(error)
});

export default myAxios;