import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import '@/assets/style.css'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import mavonEditor from'mavon-editor';
import'mavon-editor/dist/css/index.css';

import 'highlight.js/styles/atom-one-dark.css'
// import '@/assets/styles/1c-light.css'
import hljs from 'highlight.js/lib/common'
window.hljs = hljs;

const app = createApp(App).use(ElementPlus,{locale}).use(store).use(router).use(mavonEditor);
app.mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}