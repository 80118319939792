import { createStore } from 'vuex'
import axios from '@/utils'

export default createStore({
    state: {
        namespace: [],
        namespaceActive: 'default',
    },
    getters: {
        getNamespace(state) {
            return state.namespace;
        },
        getNamespaceActive(state) {
            return state.namespaceActive;
        }
    },
    mutations: {
        setNamespace(state, namespace) {
            state.namespace = namespace;
        },
        setNamespaceActive(state, namespace) {
            state.namespaceActive = namespace;
        }
    },
    actions: {
        getNameSpace(context){
            return axios.get('/api/v1/namespaces').then(res=>{
                let list = res?.data?.items || [];
                list = list.map(i=>{
                    return i?.metadata?.name;
                })
                context.commit('setNamespace', list);
                if(list.length){
                    context.commit('setNamespaceActive', list[0]);
                }
            })
        }
    },
    modules: {
    }
})
