<template>
    <div style="background:#fff;">
        <router-view class="router-view" />
    </div>
</template>
<script>
export default {
    name: 'App',
    data () {
        return {
        }
    },
}
</script>
<style>
:root{
    --header-height: 50px;
}
</style>
<style scoped>
.page-container{background:linear-gradient(#ffffff, #f6f7f9 28%);}
.page-menu {border-right:0; background-color:transparent;}
.page-menu .el-menu-item{height:40px; line-height:40px; background-color:transparent; color:rgba(0, 0, 0, 0.65);}
.page-menu .el-menu-item:hover{background-color:rgba(0, 0, 0, 0.04); }
.page-menu .el-menu-item.is-active{background-color:rgba(0, 0, 0, 0.04); color:#333;}

.header{height:var(--header-height); box-sizing:border-box; background:rgba(255, 255, 255, 0.6); border-bottom:1px solid rgba(5, 5, 5, 0.06);}
.header .logo{padding:10px 20px;}
.aside{background:transparent; border-right: solid 1px rgba(5, 5, 5, 0.06);}
.main-container{height: calc(100vh - var(--header-height));}
.main-container .main-box{padding:0; height:calc(100vh - var(--header-height)); background:#f0f2f5;}
/* .router-view{} */
</style>
